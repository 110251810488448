// src/components/GallerySection.js

import React, { useState } from "react";

const GallerySection = ({ mainImage, images }) => {
  const [currentMainImage, setCurrentMainImage] = useState(mainImage);
  const title = "Hvad vi tilbyder";
  const description = `Vi ser frem til at byde dig velkommen og give dig en genoplivende oplevelse, der vil efterlade dig fornyet og revitaliseret. Uanset om du søger lindring fra muskelspændinger, ønsker at forbedre din fleksibilitet, eller blot har brug for en stund til at genoplade, er vores thai massage den perfekte løsning. Træd ind i vores rolige omgivelser og lad vores massører tage dig med på en rejse mod indre balance og velvære.`;

  return (
    <section
      id="gallery"
      className="flex flex-col items-center p-4 my-8 md:flex-row md:justify-center md:space-x-4"
    >
      <div className="p-4 md:w-2/3 md:max-w-lg">
        <h2 className="mb-4 text-2xl font-bold uppercase text-[#333]">
          {title}
        </h2>
        <p className="text-gray-700">{description}</p>
      </div>
      <div className="flex items-center justify-center p-4 md:w-1/3 ">
        <div className="grid w-full gap-2">
          <div
            className="relative"
            style={{ width: "100%", paddingBottom: "75%" }}
          >
            <img
              className="absolute inset-0 object-cover w-full h-full rounded-lg"
              src={currentMainImage}
              alt="Main"
            />
          </div>
          <div className="grid grid-cols-3 gap-2">
            {images.map((image, index) => (
              <div key={index} className="h-3/5">
                <img
                  className="object-cover w-full h-full rounded-lg cursor-pointer"
                  src={image.src}
                  alt={image.alt}
                  onMouseEnter={() => setCurrentMainImage(image.src)}
                  onMouseLeave={() => setCurrentMainImage(mainImage)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
