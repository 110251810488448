import React from "react";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="bg-primaryBackgroundColor">
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <motion.span
          className="text-white tracking-[.25em] uppercase text-xs sm:text-sm font-serif"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Massage i Horsens
        </motion.span>
        <motion.h1
          className="mt-8 mb-6 text-3xl font-extrabold leading-tight text-white sm:text-4xl md:text-5xl lg:text-6xl sm:mt-12 md:mt-14"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.2, ease: "easeOut" }}
        >
          Velkommen til <span className="text-white">August Thai Massage</span>
        </motion.h1>
        <motion.p
          className="mb-8 text-xl font-medium leading-tight text-white sm:text-2xl md:text-3xl"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.4, ease: "easeOut" }}
        >
          Massage service i Horsens
        </motion.p>
        <motion.a
          href="#contact"
          className="inline-block px-4 py-2 my-6 font-bold tracking-widest text-black uppercase border-2 shadow-lg sm:px-6 sm:py-3 sm:my-8 border-buttonColor bg-buttonColor hover:bg-transparent rounded-s hover:shadow-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.6, ease: "easeOut" }}
        >
          Bestil en tid
        </motion.a>
      </div>
    </div>
  );
};

export default Hero;
