import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Gallery from "./components/Gallery";
import photo1 from "./assets/1.jpg";
import photo2 from "./assets/2.jpg";
import photo3 from "./assets/3.jpg";
import photo4 from "./assets/4.png";
import Contact from "./components/Contact";

const images = [
  {
    src: photo2,
    alt: "Image 1",
  },
  {
    src: photo3,
    alt: "Image 2",
  },
  {
    src: photo4,
    alt: "Image 3",
  },
];

export default function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <Gallery mainImage={photo1} images={images} />
      <Contact />
    </>
  );
}
