import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="py-8 bg-gray-200">
      <div className="flex flex-col items-center justify-center space-y-8 md:flex-row md:space-y-0 md:space-x-8 lg:space-x-32">
        <div className="flex flex-col items-start space-y-8 md:space-y-0 md:space-x-8 md:flex-row lg:space-x-32">
          <div className="flex flex-col items-start space-y-4 md:space-y-2">
            <h2 className="text-2xl md:text-3xl font-bold text-[#333]">
              Kontakt os
            </h2>
            <p className="text-lg md:text-xl">+45 91 19 60 67</p>
          </div>
          <div className="flex flex-col items-start space-y-4 md:space-y-2">
            <h2 className="text-2xl md:text-3xl font-bold text-[#333]">
              Adresse
            </h2>
            <p className="text-lg md:text-xl">
              Sønderbrogade 83 kld 8700 Horsens
            </p>
          </div>
          <div className="flex flex-col items-start space-y-4 md:space-y-2 lg:ml-32">
            <h2 className="text-2xl md:text-3xl font-bold text-[#333]">
              Åbningstider
            </h2>
            <p className="text-lg md:text-xl">Man- til Lørdag</p>
            <p className="text-lg md:text-xl">08:00 - 21:00</p>
          </div>
          <div className="flex flex-col items-start mb-16 space-y-4 md:space-y-2">
            <h2 className="text-2xl md:text-3xl font-bold text-[#333]">
              Find os
            </h2>
            <div className="relative w-full max-w-[750px]">
              <iframe
                className="w-full h-[350px] sm:w-[600px] sm:h-[300px] md:w-[750px] md:h-[350px] shadow-xl"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=750&amp;height=350&amp;hl=en&amp;q=S%C3%B8nderbrogade%2083+(August%20Thai%20Massage)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
