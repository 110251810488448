import React from "react";
import Logo from "../assets/Logo.png";

const Navbar = () => {
  return (
    <nav className="w-full shadow-lg bg-primaryBackgroundColor">
      <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center text-white uppercase">
          <img src={Logo} className="h-10 sm:h-12 md:h-14" alt="Logo" />
          <div className="flex ml-6 space-x-4">
            <a
              href="#gallery"
              className="px-3 py-2 text-sm font-bold rounded-md sm:text-base hover:text-gray-700"
            >
              Services
            </a>
            <a
              href="#contact"
              className="px-3 py-2 text-sm font-bold rounded-md sm:text-base hover:text-gray-700"
            >
              Kontakt
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
